.wrapper {
  height: 45px;
  margin: 0 auto;
  max-width: 440px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.wrapper:before {
  border-bottom: 45px solid rgba(0, 0, 0, 0.7);
  border-left: 25px solid transparent;
  border-radius: 60px 60px 0 0 / 78px 78px 0 0;
  border-right: 25px solid transparent;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.container {
  align-items: center;
  color: #fff;
  display: flex;
  height: 100%;
  margin: 0 auto;
  max-width: 370px;
  position: relative;
  z-index: 2;
}

.timeContainer {
  align-items: center;
  display: flex;
  width: 100%;
}

.time {
  font-size: 12px;
  flex: 0 0 71px;
  text-align: center;
}

.timeSliderContainer {
  flex: 1;
  padding-left: 15px;
}
