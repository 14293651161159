.button {
  background: linear-gradient(to bottom, #f4dd85, #9d4c0b);
  border: 1px solid #5a2903;
  border-radius: 5px;
  color: #853600;
  cursor: pointer;
  font-family: 'Futura';
  font-weight: 700;
  font-size: 14px;
  padding: 5px 10px;
  text-transform: uppercase;
  transition: 0.3s background;
}

.button[disabled] {
  border-color: #666;
  color: #333;
  background: linear-gradient(to bottom, #aaa, #666);
  cursor: not-allowed;
}

.button:not([disabled]):hover {
  background: linear-gradient(to bottom, #f4dd85, #f4dd85);
}

.button:active,
.button:focus {
  outline: none;
}
