.navigation {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigationListItem {
  margin: 0 0 5px;
}

.navigationListItem:last-child {
  margin-bottom: 0;
}

.navigationLink {
  composes: subtopicsListItem from '../Topic/Topic.module.css';
  display: block;
  margin-bottom: 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.3s background ease-out;
}

.navigationLink:hover,
.navigationLinkHightlight {
  background: rgba(2, 200, 242, 0.85);
}
