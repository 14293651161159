.footer {
  align-items: center;
  background: linear-gradient(to bottom, #2a2c2f, #000000);
  border-top: 1px solid #1f2122;
  display: flex;
  height: 60px;
  padding: 0 20px 0;
}

.footer:after {
  content: '';
  flex: 1;
}

.navigationContainer {
  flex: 1;
}

.navigation {
  font-family: 'Futura';
  font-weight: 700;
  position: relative;
  z-index: 9;
}

.navigation:hover .navigationDropdown {
  display: block;
}

.navigationLabel {
  background: #000;
  border-color: rgba(255, 255, 255, 0.15);
  border-radius: 27px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0px -5px 10px 0px rgba(255, 255, 255, 0.1) inset;
  color: #02c8f2;
  font-size: 12px;
  height: 24px;
  line-height: 22px;
  margin: 0;
  padding: 0 10px;
  position: relative;
  text-transform: uppercase;
  width: 240px;
  z-index: 2;
}

.mobileNavigation:after,
.navigationLabel:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid #fff;
  content: '';
  height: 0px;
  position: absolute;
  right: 10px;
  top: 8px;
  width: 0px;
  z-index: 2;
}

.navigationDropdown {
  border-width: 1px 1px 0 1px;
  border-color: #02c8f2;
  border-style: solid;
  border-radius: 10px 10px 0 0;
  bottom: 13px;
  display: none;
  /* display: block; */
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0 0 15px;
  position: absolute;
  overflow: hidden;
  right: 0;
  z-index: 1;
}

.navigationDropdown:after {
  background: rgba(0, 0, 0, 0.85);
  bottom: 0;
  content: '';
  height: 15px;
  left: 0;
  position: absolute;
  right: 0;
}

.navigationDropdownLink {
  background: rgba(0, 0, 0, 0.85);
  color: #fff;
  display: block;
  font-size: 18px;
  padding: 5px 10px;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s background ease-out;
}

.navigationDropdownLink:hover {
  background: rgba(2, 200, 242, 0.85);
}

.mobileNavigation {
  position: relative;
  background: #000;
  border-color: rgba(255, 255, 255, 0.15);
  border-radius: 27px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0px -5px 10px 0px rgba(255, 255, 255, 0.1) inset;
  display: block;
  font-size: 12px;
  height: 24px;
  line-height: 22px;
  margin: 0;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  z-index: 2;
}

.mobileNavigationSelect {
  appearance: none;
  background: transparent;
  box-shadow: none;
  border: none;
  color: #02c8f2;
  font-family: 'Futura';
  font-weight: 700;
  font-size: 12px;
  height: 22px;
  padding: 0 10px;
  text-transform: uppercase;
  width: 240px;
}

.mobileNavigationSelect:focus {
  outline: none;
}

.controls {
  align-items: center;
  flex: 1;
  text-align: center;
}

.btn {
  background: linear-gradient(to bottom, #434748, #000);
  border: 1px solid #000;
  border-radius: 50px;
  box-shadow: 1px 1px 3px 1px rgba(255, 255, 255, 0.15);
  color: #fff;
  cursor: pointer;
  height: 40px;
  margin: 0 2px;
  padding: 0 0 0 1px;
  position: relative;
  overflow: hidden;
  width: 40px;
  z-index: 1;
  transition: 0.3s opacity;
}

.btn:before,
.btn:after {
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s opacity;
  z-index: 1;
}

.btn:before {
  background: #02c8f2;
}

.btn:after {
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.15)
  );
}

.btn:not([disabled]):hover:after {
  opacity: 0.6;
}

.btn[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.btn:active,
.btn:focus {
  outline: none;
}

.btnPause {
  display: none;
  padding-left: 1px;
}

.btnPlay {
  padding-left: 3px;
}

.btnImage {
  display: block;
  margin: 0 auto;
  position: relative;
  width: 17px;
  z-index: 2;
}
