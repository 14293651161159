.rc-slider .rc-slider-handle {
  background: #03c1f0;
  border: none;
  height: 5px;
  margin-left: -2px;
  margin-top: 0;
  transition: 0.3s transform;
  width: 5px;
}

.rc-slider .rc-slider-handle:focus {
  box-shadow: none;
}

.rc-slider:hover .rc-slider-handle,
.rc-slider .rc-slider-handle:active {
  transform: scale(3);
}

.rc-slider .rc-slider-track {
  background-color: #03c1f0;
  border-radius: 5px 0 0 5px;
  height: 5px;
}

.rc-slider .rc-slider-rail {
  background-color: rgba(255, 255, 255, 0.2);
  height: 5px;
}
