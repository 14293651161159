@keyframes shine {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}

.shine:not([disabled]):before {
  animation: shine 2s infinite ease-in-out both;
}

.shine:not([disabled]):hover:before {
  animation: step-end;
}
