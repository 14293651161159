.wrapper {
  align-items: flex-end;
  display: flex;
  height: calc(100% - 45px);
  padding-bottom: 30px;
}

.box {
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  border: 2px solid #096984;
  display: flex;
  margin: 0 auto;
  max-width: 580px;
  padding: 15px;
  width: 100%;
}

.video {
  align-self: flex-end;
  height: 100%;
  flex: 0 0 251px;
  position: relative;
  z-index: 5;
}

.content {
  flex: 1 1 auto;
}

.titlesContainer {
  margin-bottom: 15px;
}

.title {
  color: #fff;
  font-family: 'Futura';
  font-size: 23px;
  line-height: 1;
  margin: 0;
  text-shadow: 0 0 13px #02c8f2;
  text-transform: uppercase;
}

.subtitle {
  color: #03c1f0;
  font-family: 'Futura';
  font-size: 18px;
  line-height: 1;
  margin: 0;
  text-transform: uppercase;
}
