.videoContainer {
  bottom: -18px;
  left: -117px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 1;
}

.edge {
  position: relative;
  top: -1px;
}

.loadCircleContainer {
  border-radius: 50px;
  height: 50px;
  left: 44%;
  margin: 0 auto;
  padding: 5px;
  position: absolute;
  top: 60%;
  width: 50px;
}

.loadCircle {
  position: relative;
  height: 100%;
  width: 100%;
}

.loadCircleChild {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.loadCircleChild:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #02c8f2;
  border-radius: 100%;
  animation: circleAnimation 1.2s infinite ease-in-out both;
}

.loadCircle2 {
  transform: rotate(30deg);
}

.loadCircle3 {
  transform: rotate(60deg);
}

.loadCircle4 {
  transform: rotate(90deg);
}

.loadCircle5 {
  transform: rotate(120deg);
}

.loadCircle6 {
  transform: rotate(150deg);
}

.loadCircle7 {
  transform: rotate(180deg);
}

.loadCircle8 {
  transform: rotate(210deg);
}

.loadCircle9 {
  transform: rotate(240deg);
}

.loadCircle10 {
  transform: rotate(270deg);
}

.loadCircle11 {
  transform: rotate(300deg);
}

.loadCircle12 {
  transform: rotate(330deg);
}

.loadCircle2:before {
  animation-delay: -1.1s;
}

.loadCircle3:before {
  animation-delay: -1s;
}

.loadCircle4:before {
  animation-delay: -0.9s;
}

.loadCircle5:before {
  animation-delay: -0.8s;
}

.loadCircle6:before {
  animation-delay: -0.7s;
}

.loadCircle7:before {
  animation-delay: -0.6s;
}

.loadCircle8:before {
  animation-delay: -0.5s;
}

.loadCircle9:before {
  animation-delay: -0.4s;
}

.loadCircle10:before {
  animation-delay: -0.3s;
}

.loadCircle11:before {
  animation-delay: -0.2s;
}

.loadCircle12:before {
  animation-delay: -0.1s;
}

@keyframes circleAnimation {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
