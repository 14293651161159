.alert {
  align-items: center;
  border-radius: 3px;
  display: inline-flex;
  padding: 5px;
  position: relative;
  width: auto;
}

.button:focus {
  outline: none;
}

.alertError {
  background: #b83230;
}

.alertError .button {
  border: none;
  background: transparent;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.alertInfo {
  background: #fff;
  color: #000;
}

.alertInfo .button {
  display: none;
}

.infoIcon {
  flex: 0 0 auto;
  margin-right: 5px;
  width: 16px;
}

.message {
  flex: 1 1 auto;
}
