.header {
  background-color: #1d404e;
  border-bottom: 1px solid #313131;
  font-family: 'Futura';
  margin: 0;
  padding: 10px 0;
}

.header__title {
  color: #03c1f0;
  font-size: 21px;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
}

.header__title__highlight {
  color: #fff;
  margin-right: 7px;
  text-shadow: 0 0 13px #02c8f2;
}
