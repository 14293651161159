@font-face {
  font-family: "Futura";
  src: url(./assets/fonts/futura-condensed.woff2) format("woff2"),
    url(./assets/fonts/futura-condensed.woff) format("woff"),
    url(./assets/fonts/futura-condensed.otf) format("opentype");
  font-style: normal;
  font-weight: 700;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background: url("./assets/images/background.gif") top repeat-x #012435;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.grecaptcha-badge {
  display: none !important;
}
