.video {
  left: -1000px;
  position: absolute;
  top: -1000px;
}

.videoBuffer {
  display: none;
}

.videoOutput {
  height: 314px;
  width: 420px;
}
