.alertSuccess {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 40px 20px;
  text-align: center;
}

.alertSuccessTitle {
  font-size: 18px;
  margin: 0 0 8px;
}

.alertSuccessParagraph {
  margin: 0;
}
