.form {
  padding: 20px 0 0;
}

.row {
  display: flex;
  margin: 0 -7px;
}

.formGroup {
  flex: 0 0 50%;
  padding: 0 7px;
  margin-bottom: 15px;
  max-width: 50%; /* do not remove, ie11 is so dumb that it does not respect box-sizing */
}

.formControlLabel {
  flex: 0 0 150px;
  padding-top: 12px;
  padding-right: 15px;
  text-align: right;
}

.formControlContainer {
  flex: 1;
}

.formGroupError .formControl {
  border-color: #ff4136;
}

.formControl {
  appearance: none;
  background: rgba(0, 0, 0, 0.85);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  padding: 10px;
  width: 100%;
}

.formControl[disabled] {
  opacity: 0.5;
}

.formControl::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.formControl:focus {
  outline: none;
}

.validationError {
  color: #ff4136;
  display: inline-block;
  font-size: 13px;
  margin: 5px 0 0 3px;
}

.btnContainer {
  text-align: center;
}

.btn {
  composes: button from "../../index.module.css";
  font-size: 16px;
}

.captchaPolicy {
  margin: 20px 0 0;
  padding: 0;
  text-align: center;
}

.captchaPolicy a {
  color: #0ac1f0;
}
