.introContainer {
  align-items: flex-end;
  display: flex;
  height: calc(100% - 74px);
  margin: 0 auto;
  width: 580px;
}

.videoContainer {
  align-self: flex-end;
  flex: 0 0 222px;
  height: 100%;
  position: relative;
  z-index: 5;
}

.boxContainer {
  align-self: flex-center;
  flex: 0 0 360px;
  height: 100%;
}

.info {
  color: #fff;
  position: absolute;
  margin-top: -30px;
  top: 100%;
  width: 195px;
}

.doctorCredentials {
  font-family: 'Futura';
  font-weight: 700;
}

.doctorCredentialsName {
  background: linear-gradient(to bottom, #12c3f4, #0d9fc8);
  border-radius: 30px;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.6);
  font-size: 18px;
  margin: 0;
  padding: 4px 25px;
  text-align: center;
  text-transform: uppercase;
}

.doctorCredentialsJobTitle {
  font-size: 14px;
  margin: 3px 0 0;
  text-align: center;
  text-shadow: 0 0 13px #02c8f2;
  text-transform: uppercase;
}

.getStartedButton {
  composes: button from '../../index.module.css';
  font-size: 20px;
}

.account {
  font-family: 'Futura';
  font-size: 15px;
  font-weight: 700;
  margin: 5px 0 0;
  text-align: center;
  text-transform: uppercase;
}
