.backgroundsContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.button {
  background: #000;
  border-radius: 5px;
  border: 1px solid #666;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.9);
  cursor: pointer;
  flex: 0 0 calc(33.33333% - 10px);
  margin: 5px 5px 5px;
  padding: 4px;
}

.buttonIe11 {
  flex-basis: calc(33.33333% - 20px);
}

.button:active,
.button:focus {
  outline: none;
}

.button:hover .image,
.buttonHighlighted .image {
  opacity: 0.7;
  transform: scale(1.3);
}

.imageContainer {
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
}

.image {
  display: block;
  height: 48px;
  opacity: 1;
  transform: scale(1.1);
  transition: 0.8s transform, 0.8s opacity;
  width: 80px;
  will-change: opacity, transform;
}

.paragraph {
  margin-bottom: 0;
  text-align: center;
}

.continueButton {
  composes: button from '../../index.module.css';
}
