.subtopicsList {
  list-style: none;
  margin: 15px 0 0;
  padding: 0;
  max-width: 285px;
}

.subtopicsListItem {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  padding: 5px 8px;
  margin: 0 0 5px;
}

.subtopicsListItem:last-child {
  margin-bottom: 0;
}
