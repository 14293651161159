.app {
  background: #000;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  flex-direction: column;
  margin: 15px auto 0;
  overflow: hidden;
  width: 760px;
}

.contentWrapper {
  height: 490px;
  margin: 0;
}

.content {
  background: url(../../assets/images/background.jpg);
  background-size: cover;
  height: 428px;
}

.content__bg01 {
  background-image: url(../../assets/images/background01.jpg);
}

.content__bg02 {
  background-image: url(../../assets/images/background02.jpg);
}

.content__bg03 {
  background-image: url(../../assets/images/background03.jpg);
}

.content__bg04 {
  background-image: url(../../assets/images/background04.jpg);
}

.content__bg05 {
  background-image: url(../../assets/images/background05.jpg);
}

.content__bg06 {
  background-image: url(../../assets/images/background06.jpg);
}
